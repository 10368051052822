(function (angular, _) {
    var MyHippoCommonDirectives = angular.module('MyHippoCommons.Directives');

    MyHippoCommonDirectives.directive('hpCardInfo', hpCardInfoDirective);
    hpCardInfoDirective.$inject = ['$timeout', 'toaster'];
    function hpCardInfoDirective ($timeout, toaster) {
        return {
            restrict: 'E',
            template: `
                <div class="form-control form-control-cc">
                    <i class="fa fa-cc"></i> {{ngModel}}
                </div>
            `,
            require: 'ngModel',
            scope: {
                ngModel: '='
            }
        };
    }
})(window.angular, window._);
